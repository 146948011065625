import React, { useState, useEffect, useRef } from 'react';
import './Carousel.css'; // Ensure you create this CSS file for styling

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isManual, setIsManual] = useState(false);
  const autoPlayRef = useRef();
  const manualTimeoutRef = useRef();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const resetAutoPlay = () => {
    setIsManual(false);
  };

  const handleManualNavigation = (callback) => {
    setIsManual(true);
    clearTimeout(manualTimeoutRef.current);
    manualTimeoutRef.current = setTimeout(resetAutoPlay, 20000);
    callback();
  };

  useEffect(() => {
    autoPlayRef.current = nextSlide;
  });

  useEffect(() => {
    if (!isManual) {
      const play = () => {
        autoPlayRef.current();
      };
      const interval = setInterval(play, 3000);
      return () => clearInterval(interval);
    }
  }, [isManual]);

  return (
    <section id="carousel">
      <div className="slideshow-container">
        <div className="dot-bar" style={{ textAlign: "center" }}>
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleManualNavigation(() => goToSlide(index))}
            ></span>
          ))}
        </div>
        {images.map((image, index) => (
          <div
            key={index}
            className={`mySlides fade ${index === currentIndex ? 'active' : ''}`}
          >
            <div className="numbertext">{`${index + 1} / ${images.length}`}</div>
            <img src={image.src} alt={image.alt} style={{ width: '100%' }} />
            <div className="text">{image.caption}</div>
          </div>
        ))}
        <div id="car_controls">
          <a
            className="prev"
            onClick={() => handleManualNavigation(prevSlide)}
          >
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => handleManualNavigation(nextSlide)}
          >
            &#10095;
          </a>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
