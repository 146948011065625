import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {API_URL} from '../../apis/functions';
import axios from 'axios';

const initialState = {
  articles: [],
  status: 'idle',
  error: null,
  page: 1
};

// Thunk to fetch articles
export const fetchProgArticles = createAsyncThunk(
  'articles/fetchProgArticles',
  async ({page}) => {
    const response = await axios.get(`${API_URL}/articles/type/project?page=${page}&limit=10`);
    return response.data;
  }
);

const articlesProgSlice = createSlice({
  name: 'articlesProg',
  initialState,
  reducers: {
    incrementPage(state) {
      state.page += 1;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgArticles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProgArticles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = [...state.articles, ...action.payload];
      })
      .addCase(fetchProgArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { incrementProgPage } = articlesProgSlice.actions;

export default articlesProgSlice.reducer;
