import React from 'react';
import ShortPresentation from '../../components/AboutComponents/ShortPresentation';
import Education from '../../components/AboutComponents/Education';
import Skills from '../../components/AboutComponents/Skills';
import ProfessionalCertificates from '../../components/certificates/ProfessionalCertificates';
import Certificates from '../../components/certificates/Certificates';

import './AboutPage.css';

export default function AboutPage(){
    return(
        <div class="post px-md-2">
            <section id="posts">
                <div class="post-content" id="about-design">
                    <ShortPresentation/>
                    <Education/>
                    <Skills/>
                    {/* CERTIFICATES */}
                    <section id="certificates" style={{width:'100%'}}>
                        <h2 id="certificatesprofessional">Professional Certificates</h2>
                        <h3>Click to see certificates</h3>
                        <div class="cert-div" style={{width:'100%', margin: '0', padding:'0'}}>
                            <ProfessionalCertificates/>
                        </div>
                    </section>
                    <section id="certificates" style={{width:'100%'}}>
                        <h2 id="certificatescompletion"> Certificates Of Completion</h2>
                        <h3>Click to see certificates</h3>
                        <div class="cert-div" style={{width:'100%', margin: '0', padding:'0'}}>
                            <Certificates/>
                        </div>
                    </section>
</div>
</section>

</div>
    )
}