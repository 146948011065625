import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestArticles, selectLatestArticles, selectLatestArticlesStatus, selectLatestArticlesError } from '../../store/generalArticleReducer/latestSlice';
import ArticleCard from '../../components/articleCard/ArticleCard';
import Carousel from '../../components/Carousel/Carousel';
import Loader from '../../components/loader/Loader'; 
import Error from '../../components/error/Error';

/* IMG */
/* import pres from '../../assets/carousel/presentacion.png';
import CAD from '../../assets/carousel/CAD_cover.png';
import mus from '../../assets/Images/music/'; */

const HomePage = () => {
  const dispatch = useDispatch();
  const latestArticles = useSelector(selectLatestArticles);
  const status = useSelector(selectLatestArticlesStatus);
  const error = useSelector(selectLatestArticlesError);

  const images = [
    {
      src: '../../assets/carousel/presentacion.png',
      alt: 'Cover page 1',
      caption: 'FRONT END | UI DESIGNER | BACK END | DATABASES | FULL STACK',
    },
    {
      src: '../../assets/carousel/CAD_cover.png',
      alt: 'Cover page 2',
      caption: 'CAD DEVELOPER TRAINEE',
    },
    {
      src: '../../assets/carousel/animation.png',
      alt: 'Cover page 3',
      caption: 'ANIMATION | ACMM PROJECT',
    },
    
  ];

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchLatestArticles());
    }
  }, [status, dispatch]);

  return (
    <>
    <section id="carousel">
    <div class="slideshow-container">
      <Carousel images={images} />
    </div>
    </section>
    <section id="posts">
    <h1 class="dynamic-title">Latest Posts</h1>
    <div class="post-content">

      {status === 'loading' && <Loader/>}
      {status === 'failed' && <Error error={error}/>}

      {/* PROJECTS */}
      {latestArticles.map((article) => (
            <ArticleCard article={article}/>
        ))}

    </div>
    </section>
    </>
  );
};

export default HomePage;
