// src/components/ArticleDetailPage.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchArticle } from '../../store/articleDetailReducer/articleDetailSlice';
import Loader from '../../components/loader/Loader';
import Error from '../../components/error/Error';

const ArticleDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { article, status, error } = useSelector((state) => state.articleDetail);

  useEffect(() => {
    dispatch(fetchArticle(id));
  }, [dispatch, id]);

  if (status === 'loading') {
    return <Loader/>;
  }

  if (status === 'failed') {
    return <Error>Error: {error}</Error>;
  }

  return (
    <section id="posts">
      <div class="post-content">
        <div class="proj-content">
          {article && (
            <>
              <h1 class="projTitle"> {article.title} </h1>

              {/* TAGS */}
              <div class="post-card" style={{margin:'1% 0', padding:'1%', cursor:'auto', background:'none'}}>
                <div class="post-categories" style={{width: '100%', justifyContent:'center', border:'none'}}>
                  {article.tags.map((tag, index) => (
                    <span key={index} className="tag">  
                      {tag}
                    </span>
                  ))}
                </div>
              </div>

              <h4>Posted: {new Date(article.publishedDate).toLocaleDateString()}</h4>
              <div class="proj-content-img">
                <img
                  src={article.content.find((block) => block.type === 'image').src}
                  alt={article.content.find((block) => block.type === 'image').alt}
                />
              </div>
              <h4>By Arturo César Morales Montaño | TYNIP</h4>

            {article.content.map((block, index) => (
              <div key={index} class="proj-content">

                {/* TEXT */}
                {block.type === 'text' && (
                  <div class="projText">
                  <p>{block.content}</p>
                  </div>
                  )}

                {/* IMG */}
                {(block.type === 'image' && index > 0) && (
                  <div class="proj-content-img">
                    <img src={block.src} alt={block.alt} />
                  </div>
                )}

                {/* SUBTITLE */}
                {block.type === 'subtitle' && <h2>{block.content}</h2>}

                {/* ACTION BUTTONS */}
                {block.type === 'actionButton' && (
                  <div class="projActions">
                    <a href={block.url} target="_blank" rel="noopener noreferrer">
                    {block.content}
                    </a>
                  </div>
                )}

                {/* QUOTE */}
                {block.type === 'quote' && (
                  <div class="quote">
                  <p>{block.content}</p>
                  </div>
                  )}

                  {/* FOOTER */}
                {block.type === 'footer' && (
                  <footer class="projFooter">
                    <div dangerouslySetInnerHTML={{ __html: block.footerHtml }} />
                  </footer>
                )}

              </div>
            ))}

        </>
      )}
    </div>
    </div>
    </section>
  );
};

export default ArticleDetailPage;
