import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchArticlesBySearchTerm } from '../../store/searchReducer/searchSlice';
import './searchInput.css';

export default function SearchInput({ setLoading, show = false }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const allSuggestions = [
      'STATIC WEBSITE', 'CSS', 'HTML', 'CAD', 'ENGINEERING', 'APP', 'C++', 'ARDUINO', 'JAVASCRIPT',
      'REACT', 'REDUX', 'MSW', 'JEST', 'ENZYME', 'FRONT END', 'FULL STACK', 'PERN APP', 'POSTGRESQL',
      'EXPRESS', 'NODE', 'COMPUTER SCIENCE', 'PYTHON', 'COMPUTER ARCHITECTURE', 'MIPS ASSEMBLY', 'INSTRUCTION SET ARCHITECTURE',
      'WEBSITE', 'ARTIST PAGE', 'MUSIC', 'DATA SCIENCE', 'DATA INFERENCE', 'DATA ANALYSIS', 'PANDAS', 'NUMPY', 'SEABORN',
      'MATPLOTLIB', 'MACHINE LEARNING', 'SKLEARN', 'PUBLIC SPEAKING', 'WRITTING', 'TEAMWORK', 'DEBATE', 'MEXMUN'
    ];

    const callApi = async (term) => {
        setLoading(true);
        try {
            await dispatch(fetchArticlesBySearchTerm(term.toLowerCase()));
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        navigate('/search');
        if (searchTerm.length === 0) {
            return 'Type Something To Search';
        }
        callApi(searchTerm);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.length > 0) {
            const filteredSuggestions = allSuggestions.filter(suggestion =>
                suggestion.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchTerm(suggestion);
        setSuggestions([]);
        callApi(suggestion);
        navigate('/search');
    };

    return (
      <div>
      <form onSubmit={handleSearch}>
          <span id={show === true ? 'long-search-wrapper' : 'search-wrapper'} className="align-items-center" style={{ width: '100%' }}>
              <i className="fas fa fa-search fa-fw"></i>
              <input
                  className="form-control"
                  id="search-input"
                  type="text"
                  aria-label="search"
                  value={searchTerm}
                  autoComplete="on"
                  onChange={handleInputChange}
                  placeholder="Search By Term..."
                  style={{ width: '100%' }}
              />
          </span>
      </form>
        {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li key={index} className="suggestion-item" onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
    </div>
    );
}