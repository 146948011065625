import React from 'react';
import profile from '../../assets/Images/about/intro.jpg';
export default function ShortPresentation(){
    return(
        <section class="about" id="about">
            <div class="aboutHeading">
                <h2>About me</h2>
            </div>
            <div class="about-image">
                <img src={profile} alt="Arturo Cesar Morales Montaño" onerror="this.style.display='none'"/>
            </div>
            <div class="about-content">
                <h3>Software and Mechatronic Junior @TEC CCM | Student Researcher | Social Impact</h3>
                <p>
                    "Hi! I am Arthur, but you can call me TYNIP | 20 years old | Developer Trainee."
                    <br/>
                    "I love learning new things, especially those that represent a great challenge. 
                    I am a friendly and social person, so I really enjoy working on team projects." 
                    <br/>
                    "I love making new friends, exercise, cook (eat), 
                    meet new people and places, and have new experiences."
                </p>
            </div>
        </section>
    )
}