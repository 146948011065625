import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_URL} from '../../apis/functions';

const initialState = {
  articles: [],
  status: 'idle',
  error: null,
};

export const fetchArticlesBySearchTerm = createAsyncThunk(
  'articles/fetchBySearchTerm',
  async (searchTerm) => {
    try {
      const response = await axios.get(`${API_URL}/articles/search?q=${searchTerm}`);
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message || 'Failed to fetch articles');
    }
  }
);

const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesBySearchTerm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArticlesBySearchTerm.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = action.payload;
      })
      .addCase(fetchArticlesBySearchTerm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default articlesSlice.reducer;

// Action creators exported for use in components
export const selectAllArticles = (state) => state.articles.articles;
export const selectArticlesStatus = (state) => state.articles.status;
export const selectArticlesError = (state) => state.articles.error;
