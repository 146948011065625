import {React, } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import SearchInput from '../searchInput/SearchInput';
import './topBar.css';

export default function Header({toggleNavPhone, loading, setLoading}) {
    const location = useLocation();
    const path = location.pathname;
    var stringArray = path.split('/');
    stringArray.shift();
    var lastBread = stringArray[stringArray.length - 1];

    const nav = useNavigate()
    return (
        <div id="topbar-wrapper">
            <div id="topbar" class="container d-flex align-items-center justify-content-between h-100">
                <span id="breadcrumb">
                    {stringArray.map(bread=>{
                        if (bread === 'certificates'){
                            return <><span> ➤ </span> <Link to={`/about/#certificatescompletion`} style={{'textDecoration': 'underline', 'color':'white'}}><span>{bread.toUpperCase()}</span></Link></>
                        }
                        if(bread === 'project'){
                            return <><span> ➤ </span> <Link to={`/projects`} style={{'textDecoration': 'underline', 'color':'white'}}><span>PROJECTS</span></Link></>
                        }
                        if (bread === lastBread){
                            return <><span> ➤ </span><span>{bread.toUpperCase()} </span></>
                        }
                        return <><span> ➤ </span> <Link to={`/${bread}`} style={{'textDecoration': 'underline', 'color':'white'}}><span>{bread.toUpperCase()}</span></Link></>
                        
                    })}
                </span>
                <i id="sidebar-trigger" class="fas fa fa-bars fa-fw" onClick={toggleNavPhone}></i>
                <div id="topbar-title"><Link to='' style={{color: 'rgb(175, 176, 177)', fontWeight:'bold'}}>Arturo César Morales Montaño</Link></div>
                <i id="search-trigger" class="fas fa fa-search fa-fw" onClick={()=>nav('/search')}></i>
                {/* SEARCH */}
                <SearchInput loading={loading} setLoading={setLoading}/>
                
            </div>
        </div>
    );
};