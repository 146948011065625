import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_URL} from '../../apis/functions';

const initialState = {
  article: null,
  status: 'idle',
  error: null
};

// Thunk to fetch a single article by ID
export const fetchArticle = createAsyncThunk('articleDetail/fetchArticle', async (id) => {
  const response = await axios.get(`${API_URL}/articles/${id}`);
  return response.data;
});

const articleDetailSlice = createSlice({
  name: 'articleDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArticle.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.article = action.payload;
      })
      .addCase(fetchArticle.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default articleDetailSlice.reducer;
