import React from 'react';
import ButtonTags from '../../components/button_tags/ButtonTags';

export default function PanelWrapper(){
    return (
        <>
            <ButtonTags/>
            <section class="access" aria-label="tags for search">
                <div id="access-tags">
                    <div class="panel-heading">Music</div>
                    <div class="music">
                        <iframe src="https://open.spotify.com/embed/artist/6Y8UzdPnFY3j8Y86p0AJTI"  frameborder="0" allowtransparency="true" allow="encrypted-media" title="Spotify"></iframe>
                    </div>
            </div>
        </section>
        </>
    )
}