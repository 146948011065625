import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_URL} from '../../apis/functions';

const initialState = {
  latestArticles: [],
  status: 'idle',
  error: null,
};

export const fetchLatestArticles = createAsyncThunk(
  'articles/fetchLatest',
  async () => {
    try {
      const response = await axios.get(`${API_URL}/articles/latest`);
      return response.data;
    } catch (error) {
      throw Error(error.response.data.message || 'Failed to fetch latest articles');
    }
  }
);

const latestArticlesSlice = createSlice({
  name: 'latestArticles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestArticles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLatestArticles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.latestArticles = action.payload;
      })
      .addCase(fetchLatestArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default latestArticlesSlice.reducer;

export const selectLatestArticles = (state) => state.latestArticles.latestArticles;
export const selectLatestArticlesStatus = (state) => state.latestArticles.status;
export const selectLatestArticlesError = (state) => state.latestArticles.error;
