import React from 'react';
import {Link} from 'react-router-dom';

const ArticleCard = ({article}) => {
  return (
    <Link to={`/${article.type}/${article._id}`}>
                <div class="post-card">
            <div class="post-main-content">
                <div class="post-header">
                    <span>Last Update</span>
                    <span>{new Date(article.publishedDate).toLocaleDateString()}</span>
                </div>
                <p class="post-heading">{article.title}</p>
                <h1 class="hidden">{article.title}</h1>
                <div class="post-categories">
                  {article.tags.map((tag, index) => (
                    <span key={index} className="tag">  
                      {tag}
                    </span>
                  ))}
                </div>
                <div class="post-text">
                  {article.content.find((block) => block.type === 'text') && (
                    <p>{article.content.find((block) => block.type === 'text').content.substring(0, 100)}...</p>
                  )}
                </div>
            </div>
            <div class="post-image">
              {article.content.find((block) => block.type === 'image') && (
                <img
                  src={article.content.find((block) => block.type === 'image').src}
                  alt={article.content.find((block) => block.type === 'image').alt}
                />
              )}
            </div>
          </div>
            </Link>
  );
};

export default ArticleCard;
