import React, {useEffect, useState} from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppLayout from './AppLayout';
import HomePage from '../pages/HomePage/HomePage';
import AboutPage from '../pages/AboutPage/AboutPage';
import ErrorPage from '../pages/ErrorPage/NotFound'
import CompletionCerPage from '../pages/AboutPage/CompletionCerPage/CompletionCerPage';
import ProfessionalCerPage from '../pages/AboutPage/ProffesionalCerPage/ProfessionalCerPage';
import ProjectPage from '../pages/ArticlesPage/ArticlesPage';
import ArticleDetail from '../pages/ArticleDetailPage/ArticleDetailPage';
import './App.css';
import './Posts.css';
import SearchPage from '../pages/SearchPage/SearchPage';


/* SCROLL */
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;

}


/* APP */
function App() {
  const [loading, setLoading] = useState(false);

  /* ROUTER */
  const router = createBrowserRouter(createRoutesFromElements(
    <>
    <Route path='/' element={
      <>
      <ScrollToTop />
      <AppLayout loading={loading} setLoading={setLoading}/>
      </>
    }>

      {/* PUBLIC ROUTES */}
      <Route index element={ <Navigate to="/home"/> }/>
      <Route path="home" exact element={<HomePage/>}/>
      <Route path="about" exact element={<AboutPage/>}/>
      <Route path="about/certificates/completion" element={<CompletionCerPage/>}/>
      <Route path="about/certificates/professional" element={<ProfessionalCerPage/>}/>
      <Route path="projects" exact element={<ProjectPage/>}/>
      <Route path="extracurricular" exact element={<ProjectPage/>}/>
      <Route path="project/:id" exact element={<ArticleDetail/>}/>
      <Route path="extracurricular/:id" exact element={<ArticleDetail/>}/>
      <Route path="search" exact element={<SearchPage loading={loading} setLoading={setLoading}/>}/>
      {/* FAIL */}
      <Route path='*' element={<ErrorPage/>} />

    </Route>
     </>
  )
  );
  
  return (
    <RouterProvider router={router} basename='/*'/>
  );
}

export default App;