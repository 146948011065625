// src/components/ProjectPage.js
import React, { useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgArticles, incrementProgPage } from '../../store/generalArticleReducer/projectsSlice';
import { fetchExtraArticles, incrementExtraPage } from '../../store/generalArticleReducer/extracurricularSlice';
import ArticleCard from '../../components/articleCard/ArticleCard';
import Loader from '../../components/loader/Loader';
import Error from '../../components/error/Error'

const ProjectPage = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  const dispatch = useDispatch();
  
  const articles = useSelector(currentPath==='projects'? (state) => state.articlesProg.articles : (state) => state.aticlesExtra.articles);
  const status = useSelector(currentPath==='projects'? (state) => state.articlesProg.status : (state) => state.aticlesExtra.status);
  const error = useSelector(currentPath==='projects'? (state) => state.articlesProg.error : (state) => state.aticlesExtra.error);
  const page = useSelector(currentPath==='projects'? (state) => state.articlesProg.page : (state) => state.aticlesExtra.page);

  useEffect(() => {
    if (status === 'idle' && currentPath === 'projects') {
      dispatch(fetchProgArticles(page));
    }
    if (status === 'idle' && currentPath === 'extracurricular'){
      dispatch(fetchExtraArticles(page));
    }
  }, [status, dispatch, page, currentPath]);

  const handleLoadMore = () => {
    if (status === 'idle' && currentPath === 'projects') {
      dispatch(incrementProgPage());
      dispatch(fetchProgArticles(page + 1));
    }
    if (status === 'idle' && currentPath === 'extracurricular'){
      dispatch(incrementExtraPage());
      dispatch(fetchExtraArticles(page + 1));
    }
  };

  return (
    <section id="posts">
    <div class="post-content">
      <h1 id="works" class="dynamic-title-proj" style={{margin: "0 0 10px 0"}}>{currentPath === 'projects'? 'PROJECT':`${currentPath.toUpperCase()}`} ARTICLES</h1>
      {status === 'loading' && <Loader/>}
      {status === 'failed' && <Error error={error}/>}

      {/* PROJECTS */}
      {currentPath === 'projects' && (
        <>
      {articles.map((article) => (
            <ArticleCard article={article}/>
        ))}

      {articles.length % 10 === 0 && articles.length !== 0 && (
        <div class="projActions">
          <button onClick={handleLoadMore} style={{background:'none', color:'white', border:'none'}}>Load More</button>
        </div>
      )}
        </>
      )}

      {/* EXTRACURRICULAR */}
      {currentPath === 'extracurricular' && (
        <>

        {articles.map((article) => (
          <ArticleCard article={article}/>
        ))}

      {articles.length % 10 === 0 && articles.length !== 0 && (
        <div class="projActions">
          <button onClick={handleLoadMore} style={{background:'none', color:'white', border:'none'}}>Load More</button>
        </div>
      )}
        </>
      )}

    </div>
    </section>
  );
};

export default ProjectPage;
