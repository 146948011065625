import React from 'react';
import TecLogo from '../../assets/Images/about/tec-logo.png';
import SDMesa from '../../assets/Images/about/mesa-logo.png';
import CodeAC from '../../assets/Images/about/code-logo.png';
import coursera from '../../assets/Images/about/cour-logo.png';
import construct from '../../assets/Images/about/cons-logo.png';
import deepLearning from '../../assets/Images/about/deep-logo.png';

export default function Education(){
    return(
        <section id="education">
            <h2>Education</h2>
            <h3>Main</h3>
            <div class="school">
                <div class="edu">
                    <div class="ab-size-img">
                        <img src={TecLogo} alt="Tec logo"/>
                    </div>
                    <p>Tecnológico de Monterrey
                    <br/>
                    </p>
                </div>
                <div class="edu">
                    <div class="ab-size-img">
                        <img src={SDMesa} alt="Tec logo"/>
                    </div>
                    <p>SD Mesa College
                    <br/>
                    </p>
                </div>
            </div>
            <h3>Secundary</h3>
            <div class="sec-edu">
                <div class="edu">
                    <div class="ab-size-img">
                        <img src={CodeAC} alt="Code Academy Logo"/>
                    </div>
                    <p>Code Academy</p>
                </div>
                <div class="edu">
                    <div class="ab-size-img">
                        <img src={coursera} alt="Coursera Logo"/>
                    </div>
                    <p>Coursera</p>
                </div>
                <div class="edu">
                    <div class="ab-size-img">
                        <img src={construct} alt="The construct Logo"/>
                    </div>
                    <p>The construct</p>
                </div>
                <div class="edu">
                    <div class="ab-size-img">
                        <img src={deepLearning} alt="Deep Learning AI Logo"/>
                    </div>
                    <p>Deep Learning AI</p>
                </div>
            </div>
        </section>
    )
}