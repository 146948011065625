import React from 'react';
export default function Error({error}){
    return (<div style={{
        width: '100%', 
        height:'70vh', 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center'
        }}>

        <span style={{fontStyle:'italic' ,padding:'2%', color:'white', background:'rgba(256, 0, 0, 0.5)', borderRadius:'10px', width:'50%'}}>{error}</span>
        </div>)
}