import {React} from 'react';
import { NavLink, Link } from 'react-router-dom';
import './header.css';
import logo from '../../assets/Images/general/Photo_Profile.jpg';

export default function Header({isNavPhoneVisible, setFalse}) {
    const sendEmail = () => {
        window.location.href = 'mailto:artcemoram@gmail.com';
      }

    return (
        <header id={isNavPhoneVisible? `sidebar`:`sidebarHide`} class="d-flex flex-column align-items-center">
    {/* MAIN PRESENTATION */}
    <div class="profile-wrapper align-items-center">
        <Link href="/home" id="avatar" class="rounded-circle" onClick={()=>setFalse()}>
            <img src={logo} width="112" height="112" alt="avatar" onerror="this.style.display='none'"/>
        </Link>
        <div class="site-title">
            <Link to="/home" onClick={()=>setFalse()}>Arturo César Morales Montaño | TYNIP | - Portfolio</Link>
        </div>
        <div class="site-subtitle">Exploring Life Together. 
            <br/>Tech For Everyone</div>
    </div>
    {/* Navigation */}
    <nav role="presentation">
        <ul class="nav flex-column flex-grow-1 w-100 ps-0">

            <NavLink to='/home' onClick={()=>setFalse()}>
                <li class="nav-item">
                    <a href="index" class="nav-link">
                        <i class="fa-fw fas fa fa-home" style={{"fontSize":"20px"}}>
                            
                        </i>
                        <span>Home</span>
                    </a>
                </li>
            </NavLink>

            <NavLink to='/about' onClick={()=>setFalse()}>
            <li class="nav-item">
                <a href="about" class="nav-link">
                    <i class="fa-fw fas fa fa-info-circle" style={{"fontSize":":20px"}}>
                    </i>
                    <span>About</span>
                </a>
            </li>
            </NavLink>
            
            <NavLink to='/projects' onClick={()=>setFalse()}>
            <li class="nav-item">
                <a href="projects.html" class="nav-link">
                    <i class="fa-fw fas fa fa-file-code-o" style={{"fontSize":":20px"}}>
                    </i>
                    <span>Projects</span>
                </a>
            </li>
            </NavLink>

            <NavLink to='/extracurricular' onClick={()=>setFalse()}>
            <li class="nav-item">
                <a href="/extracurricular" class="nav-link">
                    <i class="fa-fw fas fa fa-list-alt" style={{"fontsize":":20px"}}>
                    </i>
                    <span>Extracurricular</span>
                </a>
            </li>
            </NavLink>

            <NavLink to='/search' onClick={()=>setFalse()}>
            <li class="nav-item">
                <a href="/search" class="nav-link">
                    <i class="fa-fw fas fa fa-search" style={{"fontsize":":20px"}}>
                    </i>
                    <span>Search</span>
                </a>
            </li>
            </NavLink>
        </ul>

            {/* SIDEBAR FOTTER */}
            
            <div class="sidebar-bottom d-flex flex-wrap align-items-center w-100">
                <a href="https://github.com/TYNIP" aria-label="github" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa fa-github" style={{"fontsize":":20px"}}>
                    </i>
                </a>

                <a href="https://www.linkedin.com/in/art-moram/" aria-label="linkedin" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa fa-linkedin-square" style={{"fontsize":":20px"}}>
                    </i>
                </a>

                <a onClick={sendEmail} aria-label="mail" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa fa-envelope" style={{"fontsize":":20px"}}>
                    </i>
                </a>

                <a href="https://linktr.ee/artmoram" aria-label="linktree" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa fa-asterisk"style={{"fontSize":"20px", "color":"rgba(255,255,255,0.5)"}}>
                    </i>
                </a>

            </div>
            <div id="personal">
                <p>Arturo César Morales Montaño <br/> ©2024</p>
            </div>
        </nav>
    </header>
    );
};