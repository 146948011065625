import {React, useState, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
/* COMPONENTS */
import Header from '../components/Header/Header';
import TopBar from '../components/TopBar/TopBar';
import PanelWrapper from '../features/panelWrapper/PanelWrapper';


export default function AppLayout({loading, setLoading}) {
    const [isNavPhoneVisible, setNavPhoneVisibility] = useState(false);
    const toggleNavPhone = () => {
        setNavPhoneVisibility(!isNavPhoneVisible);
      };

    const setFalse= () => {
        if (window.innerWidth < 848) {
            setNavPhoneVisibility(false);
        }
    }
    
      /* NAVIGATION */
    useEffect(() => {
      /* Phone Navigation */
      const handlePhone = () => {
        if (window.innerWidth > 848) {
            setNavPhoneVisibility(true);
        } else{
            setNavPhoneVisibility(false);
        }
      };
      handlePhone();
      window.addEventListener('resize', handlePhone);
      return () => {
        window.removeEventListener('resize', handlePhone);
      };
    }, []);

    return (
        <section class="content-container">
            <Header isNavPhoneVisible={isNavPhoneVisible} setFalse={setFalse}/>
            <div aria-label="main profile-wrapper" id="main-wrapper" class="d-flex justify-content-center">
                <div id="main" class="container px-xxl-5">
                    <TopBar toggleNavPhone={toggleNavPhone} loading={loading} setLoading={setLoading}/>
                    <div id="main_page">
                        {/* MAIN CONTENT */}
                        <div id="core-wrapper" class="col-12 col-lg-11 col-xl-9 pe-xl-4">
                            <div class="post px-md-2">
                            <Outlet/>
                            </div>
                        </div>
                        {/* RIGHT CONTET | Panel wrapper */}
                        <div id="panel-wrapper" class="col-xl-3 ps-2 text-muted">
                            <div id="panel-wrapper-content">
                                <PanelWrapper/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};