import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../../components/certificates/Certificates.css';
import Loader from '../../../components/loader/Loader';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#363636',
    padding: '20px',
    border: 'none',
    borderRadius: '8px',
    width: '90%',
    height: '90%',
    maxHeight: '1000px',
    overflow: 'hidden',
  }
};

const AllCertificatesProfessional = () => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCert, setSelectedCert] = useState(null);

  useEffect(() => {
    fetch('/data/professional.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Sort certificates by date in descending order
        data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setCertificates(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching certificates:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <Loader/>;
  if (error) return <div style={{width:'100%', 'textAlign':'center', padding:'2%'}}>Error loading certificates: {error.message}</div>;

  const openModal = (cert) => {
    setSelectedCert(cert);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedCert(null);
  };

  return (
    <div>
      <h1 id="works" class="dynamic-title-proj" style={{"margin" : "0 0 10px 0", textAlign:'left'}}>Professional Certificates</h1>
      <div className="certificate-cards" style={{'display': 'flex','justifyContent':'center', alignItems:'center'}}>
        {certificates.map((cert, index) => (

<section id="posts">
<div class="post-content">
        <div id='pcerCard' class="post-card" key={index} onClick={() => openModal(cert)}>
            <div class="post-main-content" style={{'width': '100%'}}>
                <div class="post-header">
                    <span>Issued</span>
                    <span>{cert.date}</span>
                </div>
                <p class="post-heading">{cert.title}</p>
                <div class="post-text">
                    <p>{cert.type}</p>
                </div>
                <div class="post-categories" style={{'width':'100%'}}>
                    {cert.tags.map(tag=><span>{tag}</span>)}
                </div>
            </div>
        </div>
</div>
</section>

        ))}
      </div>

      {selectedCert && (
        <Modal 
          isOpen={modalIsOpen} 
          onRequestClose={closeModal} 
          style={modalStyles}
          contentLabel="Certificate Modal"
        >
          <button onClick={closeModal} className="close-button">Close</button>
          <embed src={selectedCert.file} width="100%" height="100%" type="application/pdf" />
        </Modal>
      )}
    </div>
  );
};

export default AllCertificatesProfessional;
