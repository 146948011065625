import React from 'react';
import {Link} from 'react-router-dom';

export default function ButtonTags(){
    return (
        <section class="access" aria-label="tags for search">
            <div id="access-tags">
                <div class="panel-heading">Trending tags</div>
                <div class="tags d-flex flex-wrap mt-3 mb-1 me-3">
                    <Link class="post-tag btn-outline-primary" to="/about/certificates/professional">Certificates</Link>
                    <Link class="post-tag btn-outline-primary" to="">CAD</Link>
                    <Link class="post-tag btn-outline-primary" to="/about">personal</Link>
                    <Link class="post-tag btn-outline-primary" to="/about#skills">skills</Link>
                    <Link class="post-tag btn-outline-primary" to="/projects">projects</Link>
                    <Link class="post-tag btn-outline-primary" to="/about/certificates/completion">Completion</Link>
                </div>
            </div>
        </section>
    )
}