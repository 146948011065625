import { combineReducers } from 'redux';
import articlesProgReducer from './generalArticleReducer/projectsSlice';
import articlesExtraReducer from './generalArticleReducer/extracurricularSlice';
import articleDetailReducer from './articleDetailReducer/articleDetailSlice';
import searchReducer from './searchReducer/searchSlice';
import latestArticlesReducer from '../store/generalArticleReducer/latestSlice';

const store = combineReducers({
    articlesProg: articlesProgReducer,
    aticlesExtra: articlesExtraReducer,
    articleDetail: articleDetailReducer,
    articles: searchReducer,
    latestArticles: latestArticlesReducer,
});

export default store;
