import React from 'react';
import {useSelector } from 'react-redux';
import {selectAllArticles, selectArticlesStatus} from '../../store/searchReducer/searchSlice';
import Loader from '../../components/loader/Loader';
import Error from '../../components/error/Error';
import SearchInput from '../../components/searchInput/SearchInput';
import ArticleCard from '../../components/articleCard/ArticleCard';

const SearchPage = ({loading, setLoading}) => {
  const articles = useSelector(selectAllArticles);
  const status = useSelector(selectArticlesStatus);

  return (
    <section id="posts">
    <div class="post-content">
        <div class="post-card">
            <div class="post-main-content">
              <p class="post-heading">Type By Term</p>
              <div style={{margin:'3% 2% 0 0'}}>
                <SearchInput setLoading={setLoading} show={true}/>
              </div>
          </div>
        </div>

      <h1 class="dynamic-title">Results</h1>

      {loading && <Loader />}
      {status === 'failed' && <Error />}

      {articles.map((article) => (
            <ArticleCard article={article}/>
        ))}

      {(articles.length <= 0) && (
        <div>Nothing Found</div>
        )}
    </div>
    </section>
  );
};

export default SearchPage;
